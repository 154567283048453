import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

export default function PacksCards(props) {
  return (
    <div className="h-full flex justify-between max-sm:flex-wrap max-md:flex-wrap my-6 px-[10rem] gap-4">
      <div className="py-6 bg-white border-2 rounded-md border-gray-200">
        <div className="border-b-2 border-gray-200">
          <div className="flex flex-col items-center">
            <img
              src="https://assets-global.website-files.com/603544f36c95c822aaf84300/644913568d7281064e3d9d83_standard%20pack%20illu.svg"
              alt=""
            />
          </div>
          <h1 className="text-3xl font-extrabold text-blue-900">standard</h1>
          <div className="flex justify-center p-4">
            <p className="text-sm w-4/5 text-gray-400">
              Déléguez vos démarches de création à nos juristes expérimentés
            </p>
          </div>
        </div>
        <div className="px-4">
          <div className="flex justify-between">
            <div className="flex items-center gap-1">
              <div>
                <img
                  src="https://assets-global.website-files.com/603544f36c95c822aaf84300/6421639cdd850a130aa3536c_file-check-crea.svg"
                  alt=""
                />
              </div>
              <dir className="flex flex-col justify-center items-start p-0">
                <h1 className="text-lg text-blue-500 font-bold">
                  Démarches de création
                </h1>
                <p className="text-sm flex gap-1">
                  + frais de greffe et d'annonce légale
                  <Tooltip
                    title="Ces frais obligatoires sont de 246,86€ HT."
                    position="top"
                    trigger="mouseenter"
                    interactive
                  >
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/6346896d583ce3f239308537_help-circle%20(1).svg"
                      alt=""
                    />
                  </Tooltip>
                </p>
              </dir>
            </div>
            <div className="flex items-center">
              <p className="font-bold text-blue-950">999MAD</p>
            </div>
          </div>
          <div className="py-2">
            <ol className="flex flex-col items-start">
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Les statuts de votre société
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Vérification de votre dossier d'immatriculation
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Publication de l'annonce légale
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Immatriculation de votre société au greffe
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Garantie anti-rejet du greffe
              </li>
            </ol>
          </div>
          <div className="flex justify-center pt-4 pb-2">
            <button
              onClick={() => {
                props.handleNextStep();
                props.handleSelection('pack', "standard")
              }}
              className="bg-blue-500 hover:bg-blue-600 py-3 px-6 w-[90%] rounded-md text-white mb-2"
            >
              Choisir
            </button>
          </div>
        </div>
      </div>
      <div className="py-6 bg-white border-2 rounded-md border-gray-200">
        <div className="border-b-2 border-gray-200">
          <div className="flex flex-col items-center">
            <img
              src="https://assets-global.website-files.com/603544f36c95c822aaf84300/644914e9a6a5ea51046f9b2f_pack-express.svg"
              alt=""
            />
          </div>
          <h1 className="text-3xl font-extrabold text-blue-900">Pro</h1>
          <div className="flex justify-center p-4">
            <p className="text-sm w-4/5 text-gray-400">
              Déléguez vos démarches de création à nos juristes expérimentés
            </p>
          </div>
        </div>
        <div className="px-4">
          <div className="flex justify-between">
            <div className="flex items-center gap-1">
              <div>
                <img
                  src="https://assets-global.website-files.com/603544f36c95c822aaf84300/6421639cdd850a130aa3536c_file-check-crea.svg"
                  alt=""
                />
              </div>
              <dir className="flex flex-col justify-center items-start p-0">
                <h1 className="text-lg text-blue-500 font-bold">
                  Démarches de création
                </h1>
                <p className="text-sm flex gap-1">
                  + frais de greffe et d'annonce légale
                  <Tooltip
                    title="Ces frais obligatoires sont de 246,86€ HT."
                    position="top"
                    trigger="mouseenter"
                    interactive
                  >
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/6346896d583ce3f239308537_help-circle%20(1).svg"
                      alt=""
                    />
                  </Tooltip>
                </p>
              </dir>
            </div>
            <div className="flex items-center">
              <p className="font-bold text-blue-950">999MAD</p>
            </div>
          </div>
          <div className="py-2">
            <ol className="flex flex-col items-start">
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Les statuts de votre société
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Vérification de votre dossier d'immatriculation
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Publication de l'annonce légale
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Immatriculation de votre société au greffe
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Garantie anti-rejet du greffe
              </li>
            </ol>
          </div>
          <div className="flex justify-center pt-4 pb-2">
            <button
              onClick={() => {
                props.handleNextStep();
                props.handleSelection('pack', "pro")
              }}
              className="bg-blue-500 hover:bg-blue-600 py-3 px-6 w-[90%] rounded-md text-white mb-2"
            >
              Choisir
            </button>
          </div>
        </div>
      </div>
      <div className="py-6 bg-white border-2 rounded-md border-gray-200">
        <div className="border-b-2 border-gray-200">
          <div className="flex flex-col items-center">
            <img
              src="https://assets-global.website-files.com/603544f36c95c822aaf84300/64259a0858fef1e37fa98257_6419b7fcd0154e9a7b9b7008_Expert%20comptable.svg"
              alt=""
            />
          </div>
          <h1 className="text-3xl font-extrabold text-blue-900">Premium</h1>
          <div className="flex justify-center p-4">
            <p className="text-sm w-4/5 text-gray-400">
              Déléguez vos démarches de création à nos juristes expérimentés
            </p>
          </div>
        </div>
        <div className="px-4">
          <div className="flex justify-between">
            <div className="flex items-center gap-1">
              <div>
                <img
                  src="https://assets-global.website-files.com/603544f36c95c822aaf84300/6421639cdd850a130aa3536c_file-check-crea.svg"
                  alt=""
                />
              </div>
              <dir className="flex flex-col justify-center items-start p-0">
                <h1 className="text-lg text-blue-500 font-bold">
                  Démarches de création
                </h1>
                <p className="text-sm flex gap-1">
                  + frais de greffe et d'annonce légale
                  <Tooltip
                    title="Ces frais obligatoires sont de 246,86€ HT."
                    position="top"
                    trigger="mouseenter"
                    interactive
                  >
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/6346896d583ce3f239308537_help-circle%20(1).svg"
                      alt=""
                    />
                  </Tooltip>
                </p>
              </dir>
            </div>
            <div className="flex items-center">
              <p className="font-bold text-blue-950">999MAD</p>
            </div>
          </div>
          <div className="py-2">
            <ol className="flex flex-col items-start">
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Les statuts de votre société
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Vérification de votre dossier d'immatriculation
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Publication de l'annonce légale
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Immatriculation de votre société au greffe
              </li>
              <li class="pack-list-item flex gap-4 text-gray-500">
                <span>✓</span> Garantie anti-rejet du greffe
              </li>
            </ol>
          </div>
          <div className="flex justify-center pt-4 pb-2">
            <button
              onClick={() => {
                props.handleNextStep();
                props.handleSelection('pack', "premium")
              }}
              className="bg-blue-500 hover:bg-blue-600 py-3 px-6 w-[90%] rounded-md text-white mb-2"
            >
              Choisir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
