import { useEffect, useLayoutEffect, useState } from "react";
import "./style.css";
import { initializeYCPay, executePayment } from "../../../utils/YCPayService";
import { hasAccessToCheckout } from "../../../utils/accessRestriction";

export default function Checkout() {
  const [showInput, setShowInput] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [promoInput, setPromoInput] = useState("");



  function handleShowingInput() {
    setShowInput(true);
  }

  function handleShowingForm() {
    setShowForm(true);
  }

  function handleInput(value) {
    setPromoInput(value);
  }

  function handlePromoBtn() {
    if (promoInput.length === 0)
      return "opacity-0 transition-opacity duration-500";
    else return "opacity-100 transition-opacity duration-500";
  }

  useEffect(() => {
    if (showForm)
      initializeYCPay("pub_sandbox_0936050d-a5b6-44c7-91ec-c496d", true);

    // Cleanup function if needed
    return () => {
      // Perform any cleanup here
    };
  }, [showForm]); // Ensure this runs only once when the component mounts

  const handlePayment = () => {
    executePayment("tokenId", successCallback, errorCallback);
  };

  const successCallback = (response) => {
    // Your success callback code here
  };

  const errorCallback = (response) => {
    // Your error callback code here
  };

  return (
    <main
      className="h-screen flex justify-center items-center main"
      id="paymentProcess"
    >
      <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] border-2 w-[416px]">
        <div className="flex flex-col justify-center items-center gap-2 py-6 border-b-2">
          <h1 className="font-bold text-blue-950 text-3xl font-Poppins">
            Mo9awil<span className="text-orange-500">.</span>ma
          </h1>
          <p className="text-blue-500 font-bold">Votre commande</p>
        </div>

        {!showForm && (
          <div className="h-[416px] p-6 flex flex-col justify-between bg-slate-100">
            <div className="flex flex-col gap-6">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-1">
                  <span className="text-blue-500 text-xl h-full">•</span>
                  <p className="text-md text-gray-500">
                    Création SARL - Pack Standard
                  </p>
                </div>
                <p>99,00 €</p>
              </div>
              <div className="flex flex-col items-end justify-center">
                <div className="border-b flex gap-6 w-[70%] justify-end pb-3">
                  <p className="text-gray-400 text-[13px]">TVA @ 20%</p>
                  <p>19,80 €</p>
                </div>
                <div>
                  <h3 className="font-bold text-lg text-right">118,80 €</h3>
                </div>
              </div>
              <div>
                {!showInput && (
                  <p
                    onClick={handleShowingInput}
                    className="text-blue-600 cursor-pointer text-[12px] px-4"
                  >
                    Ajouter un code de réduction
                  </p>
                )}

                {showInput && (
                  <div className="flex justify-center">
                    <div className="w-[90%] flex justify-between bg-white items-center">
                      <i className="fas fa-tag text-blue-500 text-lg pl-2"></i>
                      <input
                        onChange={(e) => {
                          handleInput(e.target.value);
                        }}
                        type="text"
                        value={promoInput}
                        className="w-[80%] border-none focus:ring-0 placeholder:text-[12px] placeholder:font-medium placeholder:text-gray-400"
                        placeholder="Saisissez le code de réduction"
                      />
                      <div className={handlePromoBtn()}>
                        <button className="bg-blue-500 self text-white">
                          <span className="text-3xl px-6">&rarr;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleShowingForm}
                className="bg-blue-500 text-white  font-Poppins font-bold w-[70%] py-2"
              >
                Procéder au règlement <span className="text-lg">&rarr;</span>
              </button>
            </div>
          </div>
        )}
        {showForm && (
          <div>
            <div className="h-full flex pb-6 flex-col justify-between bg-slate-100">
              <div id="payment-container" className="h-full shadow-none" style={{borderRadius: '0px!important'}}></div>
              <div className="flex justify-center pt-2">
                <button
                  id="pay"
                  className="bg-blue-500 rounded-lg text-white  font-Poppins font-bold w-[70%] py-2"
                  onClick={handlePayment}
                >
                  Pay
                </button>
              </div>
              {/* <div className="flex flex-col gap-6">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-1">
                    <span className="text-blue-500 text-xl h-full">•</span>
                    <p className="text-md text-gray-500">
                      Création SARL - Pack Standard
                    </p>
                  </div>
                  <p>99,00 €</p>
                </div>
                <div className="flex flex-col items-end justify-center">
                  <div className="border-b flex gap-6 w-[70%] justify-end pb-3">
                    <p className="text-gray-400 text-[13px]">TVA @ 20%</p>
                    <p>19,80 €</p>
                  </div>
                  <div>
                    <h3 className="font-bold text-lg text-right">118,80 €</h3>
                  </div>
                </div>
                <div>
                  {!showInput && (
                    <p
                      onClick={handleShowingInput}
                      className="text-blue-600 cursor-pointer text-[12px] px-4"
                    >
                      Ajouter un code de réduction
                    </p>
                  )}
  
                  {showInput && (
                    <div className="flex justify-center">
                      <div className="w-[90%] flex justify-between bg-white items-center">
                        <i className="fas fa-tag text-blue-500 text-lg pl-2"></i>
                        <input
                          onChange={(e) => {
                            handleInput(e.target.value);
                          }}
                          type="text"
                          value={promoInput}
                          className="w-[80%] border-none focus:ring-0 placeholder:text-[12px] placeholder:font-medium placeholder:text-gray-400"
                          placeholder="Saisissez le code de réduction"
                        />
                        <div className={handlePromoBtn()}>
                          <button className="bg-blue-500 self text-white">
                            <span className="text-3xl px-6">&rarr;</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-center">
                <button className="bg-blue-500 text-white  font-Poppins font-bold w-[70%] py-2">
                  Procéder au règlement <span className="text-lg">&rarr;</span>
                </button>
              </div> */}
            </div>
            <div id="error-container"></div>
          </div>
        )}
      </div>
    </main>
  );
}
