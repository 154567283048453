import { useState } from "react";

export default function Faq() {
  const [whichTextVisible, setWhichTextVisible] = useState(0);
  const toggleTextVisibility = (question) => {
    if (question === whichTextVisible) setWhichTextVisible(0);
    else setWhichTextVisible(question);
  };
  return (
    <div className="h-full py-12 flex flex-col items-center gap-12">
      <div>
        <h1 className="font-bold text-3xl text-blue-950">
          Questions fréquentes
        </h1>
      </div>

      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-start w-1/3 gap-4">
          <div
          className="flex justify-between "
            onClick={() => {
              toggleTextVisibility(1);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              Pourquoi passer par LegalPlace ?
            </p>
            <p>+</p>
          </div>
          {whichTextVisible === 1 && (
            <p className="text-left text-blue-900">
              LegalPlace vous permet de créer votre société facilement,
              rapidement, et sans aucun risque de rejet par le greffe. Vos
              statuts sont parfaitement fiables juridiquement et adaptés à votre
              société. Nos experts sont disponibles par email et téléphone pour
              répondre à vos questions et créent votre société en un temps
              record grâce à leur expérience et leurs relations avec les greffes
              et autres administrations.
            </p>
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          <div
            onClick={() => {
              toggleTextVisibility(2);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              En combien de temps aurai-je mes statuts ? Et mon Kbis ?
            </p>
          </div>
          {whichTextVisible === 2 && (
            <p className="w-1/3 text-left text-blue-900">
              Vos statuts seront disponibles dès que vous aurez finalisé votre
              questionnaire. Une fois votre dossier complet, nos experts lancent
              toutes les démarches auprès du greffe et du journal d’annonce
              légale pour immatriculer votre société au plus vite. Vous recevrez
              alors votre Kbis en moyenne 8 jours après (variable selon les
              départements).
            </p>
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          <div
            onClick={() => {
              toggleTextVisibility(3);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              Que se passe-t-il après le paiement ?
            </p>
          </div>
          {whichTextVisible === 3 && (
            <p className="w-1/3 text-left text-blue-900">
              Une fois le paiement effectué, vous serez redirigés vers un
              questionnaire avec des questions complémentaires sur votre
              société. Nous vous demanderons ensuite quelques justificatifs,
              comme les pièces d'identité des associés. Une fois le
              questionnaire complété, vos statuts seront disponibles et nos
              experts traiteront votre dossier, jusqu’à l’obtention de votre
              Kbis.
            </p>
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          <div
            onClick={() => {
              toggleTextVisibility(4);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              Comment récupérer la TVA ?
            </p>
          </div>
          {whichTextVisible === 4 && (
            <p className="w-1/3 text-left text-blue-900">
              Les frais de création sont avancés par un ou plusieurs associés
              pour le compte de la société en formation. Les associés qui ont
              avancé les coûts de création de société se remboursent une fois le
              capital social de la société débloqué – et à condition que la
              société dispose de la trésorerie suffisante. La TVA afférente aux
              frais de création est déductible dans le cadre des activités de la
              société.
            </p>
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          <div
            onClick={() => {
              toggleTextVisibility(5);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              Quelles pièces dois-je fournir pour l'immatriculation de ma
              société ?
            </p>
          </div>
          {whichTextVisible === 5 && (
            <p className="w-1/3 text-left text-blue-900">
              Pour immatriculer votre société, vous devrez fournir : une pièce
              d’identité (CNI ou titre de séjour permettant une inscription au
              RCS le cas échéant), un certificat de dépôt des fonds et un
              justificatif de domicile. Pour les activités réglementées,
              d’autres justificatifs complémentaires peuvent vous être demandés.
            </p>
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          <div
            onClick={() => {
              toggleTextVisibility(6);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              Je suis artisan. Dois-je m'inscrire au Répertoire National des
              Entreprises (RNE) ?
            </p>
          </div>
          {whichTextVisible === 6 && (
            <p className="w-1/3 text-left text-blue-900">
              Si vous êtes artisan, nous effectuons aussi cette démarche et
              transmettons les justificatifs nécessaires au guichet des
              formalités des entreprises. Si vous êtes artisan commerçant, vous
              serez inscrit au répertoire des métiers, au RNE et au RCS
              (Registre du Commerce et des Sociétés).
            </p>
          )}
        </div>

        <div className="flex flex-col items-center gap-4">
          <div
            onClick={() => {
              toggleTextVisibility(7);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              J'hésite encore concernant la forme juridique de ma société.
            </p>
          </div>
          {whichTextVisible === 7 && (
            <p className="w-1/3 text-left text-blue-900">
              Pas de souci ! Vous pouvez commencer votre création et demander le
              changement de votre forme juridique avant le dépôt de votre
              dossier. N'hésitez pas à nous contacter, nous serons également
              ravis de vous répondre à vos questions.
            </p>
          )}
        </div>

        <div className="flex flex-col items-center gap-4">
          <div
            onClick={() => {
              toggleTextVisibility(8);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              Est-il possible de créer une société agricole avec LegalPlace ?
            </p>
          </div>
          {whichTextVisible === 8 && (
            <p className="w-1/3 text-left text-blue-900">
              Compte tenu des spécificités liées à ce type d'activité, il n'est
              actuellement pas possible de créer votre société avec LegalPlace.
            </p>
          )}
        </div>

        <div className="flex flex-col items-center gap-4">
          <div
            onClick={() => {
              toggleTextVisibility(9);
            }}
          >
            <p className="text-lg font-bold text-blue-950">
              D’autres services sont-ils proposés par LegalPlace (domiciliation,
              comptabilité, dépôt de capital, etc.) ?
            </p>
          </div>
          {whichTextVisible === 9 && (
            <p className="w-1/3 text-left text-blue-900">
              LegalPlace simplifie la création et la gestion d'entreprise en
              vous offrant une gamme complète de services : création
              d'entreprise, comptabilité, domiciliation d'entreprise, compte
              professionnel et dépôt de capital en ligne.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
