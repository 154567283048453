import { useState } from "react";
import { setGlobalState } from "../../utils/globalState";
import { useNavigate } from "react-router-dom";

export default function RegisterForm({ handleEmailOption }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  function handleRegister(e) {
    e.preventDefault();
    setErrors([]);
    fetch("http://127.0.0.1:8000/api/users", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        password,
        password_confirmation,
        providerId: "email",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          setErrors(data.errors);
          console.log(data.errors);
        } else {
          localStorage.setItem("token", data.token);
          setGlobalState("user", data.user);
        setGlobalState("isConnected", true);
          setEmail("");
          setPassword("");
          setName("");
          setPhone("");
          setPasswordConfirmation("");
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <form onSubmit={handleRegister} class="bg-white">
      <div className="mb-4">
        <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"
            />
          </svg>
          <input
            class="pl-2 outline-none border-none"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Full name"
          />
        </div>
        {errors.name && (
          <div>
            <p className="text-red-900">{errors.name[0]}</p>
          </div>
        )}
      </div>
      <div className="mb-4">
        <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
            />
          </svg>
          <input
            class="pl-2 outline-none border-none"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Email Address"
          />
        </div>
        {errors.email && (
          <div>
            <p className="text-red-900">{errors.email[0]}</p>
          </div>
        )}
      </div>
      <div className="mb-4">
        <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clip-rule="evenodd"
            />
          </svg>
          <input
            class="pl-2 outline-none border-none"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Password"
          />
        </div>
        {errors.password && (
          <div>
            <p className="text-red-900">{errors.password[0]}</p>
          </div>
        )}
      </div>
      <div className="mb-4">
        <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clip-rule="evenodd"
            />
          </svg>
          <input
            class="pl-2 outline-none border-none"
            type="password"
            value={password_confirmation}
            onChange={(e) => {
              setPasswordConfirmation(e.target.value);
            }}
            placeholder="Re-type Password"
          />
        </div>
      </div>
      <div className="mb-4">
        <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
            />
          </svg>
          <input
            class="pl-2 outline-none border-none"
            type="text"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            placeholder="Phone (Optional)"
          />
        </div>
        {errors.phone && (
          <div>
            <p className="text-red-900">{errors.phone[0]}</p>
          </div>
        )}
      </div>
      <div className="block w-full bg-indigo-600 mt-4 py-2 rounded-2xl  mb-2">
        <button type="submit" class="text-white font-semibold">
          Create An Account
        </button>
      </div>
      <div className="block w-full bg-gray-800 mt-4 py-2 rounded-2xl  mb-2">
        <button
          onClick={handleEmailOption}
          type="button"
          class="text-white font-semibold"
        >
          Login
        </button>
      </div>
      <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer">
        Forgot Password ?
      </span>
    </form>
  );
}
