import { Tooltip } from "react-tippy";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function Payement(props) {
  function handlePaymentMethod(method) {
    if (method === "total") {
      props.handleSelection("payTotal", true);
    } else {
      props.handleSelection("payTotal", false);
    }
  }

  useEffect(() => {
    props.handleSelection("choicesCompleted", true);
  }, []);

  return (
    <div className="flex justify-center bg-white">
      <div className="w-full px-[11rem] max-sm:px-2 max-md:px-6 max-lg:px-12 flex flex-col">
        <div className="flex max-sm:flex-wrap max-lg:flex-wrap justify-evenly max-sm:justify-center gap-7">
          {/* LEFT UPPER SIDE */}
          <div className="w-3/5 md:w-full max-sm:w-full lg:w-full flex flex-col">
            <div className="border-2 rounded-lg max-lg:w-full border-gray-200 max-md:w-full max-sm:w-full p-6 px-8 flex flex-col gap-8">
              <div>
                <h1 className="text-blue-950 font-extrabold rounded-lg  text-2xl">
                  Récapitulatif de votre commande
                </h1>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex justify-between">
                  <h3 className="text-blue-950 font-extrabold rounded-lg  text-xl">
                    Création Standard
                  </h3>
                  <p className="text-red-400 font-extrabold rounded-lg  text-xl">
                    99,00€
                  </p>
                </div>
                <ol className="flex flex-col gap-4">
                  <li className="flex border-b pb-2 justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Statuts de votre société</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Statuts personnalisés, générés automatiquement grâce aux réponses renseignées dans le questionnaire"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                  <li className="flex border-b pb-2 justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Dossier complet</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Cerfa M0, pièces annexes obligatoires, registre des bénéficiaires effectifs"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                  <li className="flex border-b pb-2 justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Immatriculation auprès du greffe</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Nous faisons les démarches nécessaire pour immatriculer votre société auprès du greffe"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                  <li className="flex border-b pb-2 justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Publication de l'annonce légale</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Formalité obligatoire dans le cadre d'une constitution de société"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                  <li className="flex border-b pb-2 justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Obtention du kbis</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Vous obtiendrez votre Kbis après vérification de votre dossier dans un délai de 10 jours en moyenne"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                  <li className="flex border-b pb-2 justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Garantie anti-rejet</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Il arrive parfois que le greffe rejette des dossiers afin de demander des informations complémentaires. Nos experts gèrent tous les cas de rejet"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                  <li className="flex border-b pb-2 justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Vérification du dossier</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Nos formalistes vérifient et valident votre dossier, ils prennent contact avec vous au besoin"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                </ol>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex justify-between">
                  <h3 className="text-blue-950 font-extrabold rounded-lg flex gap-2 text-xl">
                    Frais Administratifs{" "}
                    <span className="text-white bg-blue-950 rounded-sm text-sm font-md flex items-center px-2">
                      OBLIGATOIRE
                    </span>
                  </h3>
                  <p className="text-red-400 font-extrabold rounded-lg  text-xl">
                    246,86 €
                  </p>
                </div>
                <ol className="flex flex-col gap-6">
                  <li className="border-b pb-2 flex justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Frais de greffe obligatoires</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Frais indépendants de LegalPlace, reversés au Greffe"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                  <li className="pb-4 flex justify-between font-medium text-blue-900 text-xl pl-2">
                    <div className="flex gap-2">
                      <span className="text-green-500 text-2xl">✔</span>
                      <span>Frais d'annonce légale obligatoire</span>
                    </div>
                    <div>
                      <Tooltip
                        title="Frais indépendants de LegalPlace, reversés au journal d'annonces légales"
                        position="right"
                        trigger="mouseenter"
                        interactive
                      >
                        <img
                          src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1b5a08dc8bd5d9ae0_Helper.svg"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          {/* RIGHT UPPER SIDE */}
          <div className="w-2/5 flex max-md:w-full max-lg:w-full flex-col gap-4">
            <div className="border-2 rounded-lg border-gray-200 p-6 px-8">
              <div className="border-b pb-2 gap-3 flex flex-col">
                <div className="hover:border-blue-400 py-2 cursor-pointer flex flex-col justify-start px-3 border border-gray-200 rounded-xl">
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <input
                        id="bordered-radio-1"
                        type="radio"
                        value=""
                        onChange={() => {
                          handlePaymentMethod("half");
                        }}
                        defaultChecked={props.payTotal ? false : true}
                        name="bordered-radio"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="bordered-radio-1"
                        className="w-full text-left cursor-pointer ms-2 text-lg text-blue-950"
                      >
                        <span>Payer aujourd’hui seulement</span>
                      </label>
                    </div>
                    <label
                      htmlFor="bordered-radio-1"
                      className="cursor-pointer"
                    >
                      <div>
                        <h3 className="text-blue-950 flex justify-center">
                          <span className="font-black text-lg">30€</span>{" "}
                          <span className="text-blue-800 text-sm font-sans">
                            HT
                          </span>
                        </h3>
                      </div>
                    </label>
                  </div>
                  <label
                    htmlFor="bordered-radio-1"
                    className="w-full flex items-start flex-col cursor-pointer ms-2 text-xl text-gray-400"
                  >
                    <p className="text-sm text-left w-[90%]">
                      Vous réglez les frais administratifs obligatoires
                      (+246,86€) lorsque votre dossier est validé (maximum 20
                      jours).
                    </p>
                  </label>
                </div>
                <div className="hover:border-blue-400 py-2 cursor-pointer flex flex-col justify-start px-3 border border-gray-200 rounded-xl">
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <input
                        id="bordered-radio-2"
                        onChange={() => {
                          handlePaymentMethod("total");
                        }}
                        defaultChecked={!props.payTotal ? false : true}
                        type="radio"
                        value=""
                        name="bordered-radio"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="bordered-radio-2"
                        className="w-full text-left cursor-pointer ms-2 text-lg text-blue-950"
                      >
                        <span>Payer la totalité</span>
                      </label>
                    </div>
                    <label
                      htmlFor="bordered-radio-2"
                      className="cursor-pointer"
                    >
                      <div>
                        <h3 className="text-blue-950 flex justify-center">
                          <span className="font-black text-lg">345,86€</span>{" "}
                          <span className="text-blue-800 text-sm font-sans">
                            HT
                          </span>
                        </h3>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              {/* price section */}
              <div className="py-4">
                <div className="flex justify-between text-md">
                  <p className="text-blue-900 text-md">Montant HT</p>
                  <p className="text-blue-900 text-md">345,86 €</p>
                </div>
                <div className="flex justify-between text-md">
                  <p className="text-blue-900 text-md">TVA déductible</p>
                  <p className="text-blue-900 text-md">69,17 €</p>
                </div>
                <div className="flex justify-between text-md">
                  <p className="text-blue-950 font-black text-md">TOTAL TTC</p>
                  <p className="text-blue-900 text-md">415,03 €</p>
                </div>
              </div>

              <div className="py-4 flex flex-col gap-4">
                <Link to="/services/creation_sarl/checkout">
                  <button className="bg-blue-500 text-white text-lg font-lg w-full py-3 rounded-md">
                    Payer en toute sécurité
                  </button>
                </Link>

                <Link to="/services/creation_sarl/checkout">
                  <p className="text-md text-blue-500">
                    Ajouter un code de réduction
                  </p>
                </Link>
                <div className="flex justify-center gap-3">
                  <Link to="/services/creation_sarl/checkout">
                    <div>
                      <img
                        src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad1da6c0e40ecb86ee3_PaymentCb.svg"
                        alt=""
                      />
                    </div>
                  </Link>

                  <Link to="/services/creation_sarl/checkout">
                    <div>
                      <img
                        src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad25fdaccb2d01b3fca_PaymentVisa.svg"
                        alt=""
                      />
                    </div>
                  </Link>

                  <Link to="/services/creation_sarl/checkout">
                    <div>
                      <img
                        src="https://assets-global.website-files.com/603544f36c95c822aaf84300/61124ad2d4e8fb5a43211677_PaymentMastercard.svg"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="border-2 rounded-lg border-gray-200 p-6 px-8 flex flex-col gap-12">
              <div className="flex justify-center">
                <h3 className="text-blue-950 font-extrabold text-2xl">
                  Les étapes pour créer votre entreprise en ligne
                </h3>
              </div>

              {/* STEPS */}
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-4">
                  <div>
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/64fb3e8509f1d7a380ab2149_Ellipse%2010.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-blue-950 text-lg">
                    Choisissez votre pack et vos options
                  </p>
                </div>
                <div className="py-2 border-l border-blue-950 mx-2 text-left"></div>
                <div className="flex items-center gap-4">
                  <div>
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/64fb3e85d40dc0f450a5d667_Ellipse%2011.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-blue-950 text-lg">Commandez en ligne</p>
                </div>
                <div className="py-2 border-l border-blue-950 mx-2 text-left"></div>
                <div className="flex items-center gap-4">
                  <div>
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/64fb3e85b2c102bb9e0ee02d_Ellipse%208.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-blue-950 text-lg">
                    Finalisez votre dossier juridique
                  </p>
                </div>
                <div className="py-2 border-l border-blue-950 mx-2 text-left"></div>
                <div className="flex items-center gap-4">
                  <div>
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/64fb3e85b2c102bb9e0ee02d_Ellipse%208.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-blue-950 text-lg">
                    Ajoutez vos justificatifs
                  </p>
                </div>
                <div className="py-2 border-l border-blue-950 mx-2 text-left"></div>
                <div className="flex items-center gap-4">
                  <div>
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/64fb3e85b2c102bb9e0ee02d_Ellipse%208.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-blue-950 text-lg">
                    Un formaliste traite votre dossier
                  </p>
                </div>
                <div className="py-2 border-l border-blue-950 mx-2 text-left"></div>
                <div className="flex items-center gap-4">
                  <div>
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/64fb3e85b2c102bb9e0ee02d_Ellipse%208.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-blue-950 text-lg">
                    Votre dossier est envoyé au Greffe
                  </p>
                </div>
                <div className="py-2 border-l border-blue-950 mx-2 text-left"></div>
                <div className="flex items-center gap-4">
                  <div>
                    <img
                      src="https://assets-global.website-files.com/603544f36c95c822aaf84300/64fb3e85b2c102bb9e0ee02d_Ellipse%208.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-blue-950 text-lg">
                    Vous recevez votre KBIS
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-black text-lg">Satisfaction Garantie</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
