import { Link } from "react-router-dom";

export default function STEP_12({ handleSelection, needs }) {
  function handleNeeds(value) {
    if (!needs.includes(value)) {
      handleSelection("needs", [...needs, value]);
    } else {
      handleSelection(
        "needs",
        needs.filter((item) => item !== value)
      );
    }
  }
  return (
    <div className="w-full h-screen">
      <div className="pt-6 w-full flex justify-center">
        <h1 className="font-bold text-xl text-blue-950 w-2/6">
          Que recherchez-vous ?
        </h1>
      </div>
      <div className="w-full mt-16">
        <div className="flex justify-center w-full">
          <div className="my-2 w-1/3 flex justify-center flex-col gap-2">
            <div className="cursor-pointer hover:border-blue-400 flex items-center justify-start ps-4 border border-gray-200 rounded">
              <input
                id="bordered-radio-2"
                type="checkbox"
                value=""
                onClick={() => {
                  handleNeeds(
                    "Créer ma société en déléguant toutes les démarches"
                  );
                }}
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={needs.includes(
                  "Créer ma société en déléguant toutes les démarches"
                )}
              />
              <label
                htmlFor="bordered-radio-2"
                className="w-full flex cursor-pointer py-4 ms-2 text-lg text-gray-500"
              >
                Créer ma société en déléguant toutes les démarches
              </label>
            </div>
            <div className="hover:border-blue-400 cursor-pointer flex items-center justify-start ps-4 border border-gray-200 rounded">
              <input
                id="bordered-radio-1"
                type="checkbox"
                value=""
                onClick={() => {
                  handleNeeds("Seulement des statuts");
                }}
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={needs.includes("Seulement des statuts")}
              />
              <label
                htmlFor="bordered-radio-1"
                className="w-full flex cursor-pointer py-4 ms-2 text-lg text-gray-500"
              >
                Seulement des statuts
              </label>
            </div>

            <div className="hover:border-blue-400 cursor-pointer flex items-center justify-start ps-4 border border-gray-200 rounded">
              <input
                id="bordered-radio-3"
                type="checkbox"
                value=""
                onClick={() => {
                  handleNeeds(
                    "Une solution tout en un (création, comptabilité, banque, etc..)"
                  );
                }}
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={needs.includes(
                  "Une solution tout en un (création, comptabilité, banque, etc..)"
                )}
              />
              <label
                htmlFor="bordered-radio-3"
                className="w-full flex cursor-pointer py-4 ms-2 text-lg text-gray-500"
              >
                Une solution tout en un (création, comptabilité, banque, etc..)
              </label>
            </div>
            <div className="hover:border-blue-400 cursor-pointer flex items-center justify-start ps-4 border border-gray-200 rounded">
              <input
                id="bordered-radio-4"
                type="checkbox"
                value=""
                onClick={() => {
                  handleNeeds("Du conseil juridique et fiscal avec comptable");
                }}
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={needs.includes(
                  "Du conseil juridique et fiscal avec comptable"
                )}
              />
              <label
                htmlFor="bordered-radio-4"
                className="w-full flex cursor-pointer py-4 ms-2 text-lg text-gray-500"
              >
                Du conseil juridique et fiscal avec comptable
              </label>
            </div>
            <div className="hover:border-blue-400 cursor-pointer flex items-center justify-start ps-4 border border-gray-200 rounded">
              <input
                id="bordered-radio-5"
                type="checkbox"
                value=""
                onClick={() => {
                  handleNeeds("Je me renseigne");
                }}
                name="bordered-radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={needs.includes("Je me renseigne")}
              />
              <label
                htmlFor="bordered-radio-5"
                className="w-full flex cursor-pointer py-4 ms-2 text-lg text-gray-500"
              >
                Je me renseigne
              </label>
            </div>
          </div>
        </div>
        <div className="pt-12">
          <Link
            to="packs"
          >
            <button className="bg-blue-500 hover:bg-blue-600 py-4 px-6 rounded-md text-white mb-2">
              Continuer
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
