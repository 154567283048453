import { useState } from "react";
import { setGlobalState } from "../../utils/globalState";
import { useNavigate } from "react-router-dom";

export default function LoginForm({ handleRegisterForm }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  function handleLogin(e) {
    e.preventDefault();
    setErrors([]);
    fetch("http://127.0.0.1:8000/api/user/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          localStorage.setItem("token", data.token);
          setGlobalState("user", data.user);
          setGlobalState("isConnected", true);
          setEmail("");
          setPassword("");
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <form class="bg-white" onSubmit={handleLogin}>
      <div className="mb-4">
        <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
            />
          </svg>
          <input
            class="pl-2 outline-none border-none"
            type="text"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            placeholder="Email Address"
          />
        </div>
        {errors.email && (
          <div>
            <p className="text-red-900">{errors.email[0]}</p>
          </div>
        )}
      </div>
      <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clip-rule="evenodd"
          />
        </svg>
        <input
          class="pl-2 outline-none border-none"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="text"
          value={password}
          placeholder="Password"
        />
      </div>
      {errors.password && (
        <div>
          <p className="text-red-900">{errors.password[0]}</p>
        </div>
      )}
      <div className="block w-full bg-indigo-600 mt-4 py-2 rounded-2xl  mb-2">
        <button type="submit" class="text-white font-semibold">
          Login
        </button>
      </div>

      <div className="block w-full bg-gray-800 mt-4 py-2 rounded-2xl  mb-2">
        <button
          onClick={handleRegisterForm}
          type="button"
          class="text-white font-semibold"
        >
          Create An Account?
        </button>
      </div>
      <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer">
        Forgot Password ?
      </span>
    </form>
  );
}
