import LoginPopUP from "../../components/auth/Login";
import { signInWithPopup, getAuth } from "firebase/auth";
import { setGlobalState } from "../../utils/globalState";
import { fetchData } from "../../utils/api";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  async function login(provider) {
    try {
      const auth = getAuth();
      //authenticate the user by calling a popup
      const result = await signInWithPopup(auth, provider);

      fetchData.post("http://127.0.0.1:8000/api/users", result, (data) => {
        localStorage.setItem('token', data.token);
        setGlobalState("user", data.user);
        setGlobalState("isConnected", true);
        console.log(data.user)
        navigate("/");
      });
    } catch (e) {
      console.log(`login error ${e}`);
    }
  }
  return <LoginPopUP login={login} />;
}
